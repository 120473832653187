
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { App } from '../api/src/models/App';
import { Resource } from '../api/src/models/Resource';
import uiMixin from '../mixins/ui';

const resourceName = Resource.ResourceName.enum.Radia;

export default {
  name: 'SystemUpgrades',
  mixins: [
    uiMixin,
  ],
  data() {
    return {
      resource: Resource.Setting[resourceName],
      resourceName,
      isUpgrading: false,
      upgradingTimeMs: 3000,
      upgradingProgress: 0,
    };
  },
  computed: {
    countResource() {
      return this.$store.getters['resources/countRadia'];
    },
    upgrades() {
      return this.$store.getters['systems/upgrades'].map((upgrade) => {
        const isMax = !upgrade.valueDisplayNext;
        const progress = Math.max(0.01, Math.min(1, upgrade.valueIndex / (upgrade.values.length - 1)));
        return {
          ...upgrade,
          isMax,
          progress,
          progressStyle: {
            width: `${progress * 100}%`,
            backgroundColor: this.resource.color,
            opacity: isMax ? 0.5 : 1,
          },
        };
      });
    },
  },
  methods: {
    async handleSelect(upgrade) {
      this.upgradingProgress = 0;

      if (upgrade && this.upgrades.find(u => u.name === upgrade.name) && !upgrade.isMax) {
        Haptics.impact({ style: ImpactStyle.Medium });
        const setting = Resource.Setting[resourceName];
        const isPurchasing = await this.uiMsgBoxConfirm(
          `Are you sure you want to upgrade "${upgrade.label}" to ${upgrade.valueDisplayNext} for ${upgrade.cost} ${setting.label.toLowerCase()}?`,
        );

        if (isPurchasing) {
          this.isUpgrading = true;

          const progressInterval = setInterval(() => {
            /* one-tenth of total progress (100 / 10 = 10)
             * but because of progressbar animation, it lags behind
             * so we double it to make up for the animation lag
             */
            this.upgradingProgress = this.upgradingProgress + 20;
          }, this.upgradingTimeMs / 10); // divide into 10 intervals

          setTimeout(async () => {
            clearInterval(progressInterval);
            const result = await this.$store.dispatch('userSettings/setSystemUpgradeValueIndex', {
              name: upgrade.name,
              valueIndex: upgrade.valueIndex + 1,
            });

            if (result) {
              await this.$store.dispatch('userSettings/editResources', [{
                count: this.countResource - upgrade.cost,
                name: resourceName,
              }]);
              this.isUpgrading = false;
              this.uiNotify('Upgrade complete!', { conceptName: App.Concept.ConceptName.enum.SystemUpgrades });
              this.uiNotify(upgrade.cost, {
                type: 'ResourceUse',
                payload: {
                  resourceName,
                },
                severity: 'secondary',
              });
              Haptics.vibrate();
            }
          }, this.upgradingTimeMs);
        }
      }
    },
  },
};
